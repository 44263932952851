.cta {
	.form-input {
		border-color: #2f3037;
		border-radius: 6px;
	}
}

.cta-inner {
	padding-left: 10px;
	padding-right: 10px;
	background-color: get-color(light, 4);
	// background-image: url();
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right bottom;
}

@include media('>medium') {
	.cta {
		.form-input {
			min-width: 400px;
		}
	}

	.cta-inner {
		// padding-left: 20px;
		// padding-right: 20px;
	}
}
